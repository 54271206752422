declare global {  
    interface Date {  
     getUTCDateExtention(): Date;  
    }  
}  
Date.prototype.getUTCDateExtention = function(): Date {  
    var newdate = new Date(this);   
    let utcDate = new Date(Date.UTC(newdate.getFullYear(), newdate.getMonth(), newdate.getDate()));
    return utcDate;    
}  
export {};