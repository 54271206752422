
export class EmployeeModel
{
  iEmployeeId : number;
  iBusinessUnitId:number;  
  strFirstName:string;
  strLastName:string; 
  strFullName:string;  
}

