﻿import { Component, OnInit } from '@angular/core';

declare var $: any;
declare var jQuery: any;
@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
 
  constructor() { }

  ngOnInit() {
        /*select 2*/
      if ($('.select2').length > 0) {
          $('.select2:not(.filter)').each(function () {
              if (!$(this).parents('code').length) {
                  $(this).select2({
                      width: 'style',
                      minimumResultsForSearch: -1,
                      theme: 'uikit'
                  });
              }
          });
      }
      if ($('.select2.filter').length > 0) {
          $('.select2.filter').each(function () {
              if (!$(this).parents('code').length) {
                  $(this).select2({
                      width: 'style',
                      minimumResultsForSearch: 10,
                      theme: 'uikit'
                  });
              }
          });
      }

      // $('.form-item-wrap--header-select select.select2').data('select2').$dropdown.addClass("header-select-dropdown");

      $('select.select2').on('select2:select', function () {
          $(this).siblings('.select2-container').addClass('select2-container--selected');
          $(this).parents('.form-item-wrap').addClass('focused');
      });


      /*time picker*/
      /********************TIMEPICKER*********************/

  }

}
