import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MessageService {

  constructor() { }
  private subject = new Subject<any>();
  private subjectSelectedBusinessUniIdOnDashboard = new Subject<number>();

  sendMessage(iSelectedBusinessUnitId: number, listBu: Array<any>) {
    this.subject.next({ iSelectedBusinessUnitId: iSelectedBusinessUnitId, listBu: listBu });
  }
  sendSelectedBusinessUniIdOnDashboard(iSelectedBusinessUnitId: number) {
    this.subjectSelectedBusinessUniIdOnDashboard.next(iSelectedBusinessUnitId);
  }
  clearMessage() {
    this.subject.next();
    this.subjectSelectedBusinessUniIdOnDashboard.next();
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
  getSelectedBusinessUniIdOnDashboard(): Observable<number> {
    return this.subjectSelectedBusinessUniIdOnDashboard.asObservable();
  }
}
