export class FormulaSetupModel {
    iFormulaSetupId: number;
    formulaModel: FormulaModel = new FormulaModel();
  }
  export class FormulaModel {
    tShowSalesOnScheduling: boolean;
    tShowForecastOnScheduling: boolean;
    salesSetup: SalesSetupModel = new SalesSetupModel();
  }
  
  export class SalesSetupModel {
    iSalesTotal: number;
    iGuestTotal: number;
    iBudgetFrom:number;
    iForcastFrom: number;
    iSalesReportFrom: number;
  }
