import { EmployeeScheduleModel } from './EmployeeScheduleModel'

export class ParamEmployeeSchedule {
  dateTimeFrom: string;
  dateTimeTo: string;
  iBusinessUnitId: number;
  iTimeClockView: number = 1;
  listUserDepartmentAccess: Array<number>
  tScheduleByDay: boolean;
  iComparisionFilter: number = 1;
  strEmployeeSearch: string;
  tIsActual: boolean
}

export class ParamEmployeeScheduleSave {
  dateTimeFrom: string;
  dateTimeTo: string;
  listEmployeeScheduleModel: Array<EmployeeScheduleModel>;
  tIsActual: boolean;
  strEmailAddress: string;
  tIsPublished : boolean;
  tCanEditPublish : boolean;
}
