import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as env from '../../environments/environment';
import {ScheduleNotes} from '../Models/schedulenotes.model';

@Injectable()
export class ScheduleNotesService {

  constructor(private httpClient: HttpClient) { }
  getScheduleNotes(iBusinessUnitId: string, dateTimeDay: string) {
    return this.httpClient.get(env.configUrl + 'ScheduleNotes?iBusinessUnitId=' + iBusinessUnitId + '&dateTimeDay=' + dateTimeDay);
  }

  saveScheduleNotes(scheduleNotes:ScheduleNotes) {
    return this.httpClient.post(env.configUrl + "ScheduleNotes",scheduleNotes);
  }
}
