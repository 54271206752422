import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Router } from "@angular/router"
import { LoaderService } from './loader.service';


@Injectable()
export class Interceptor implements HttpInterceptor {
  private totalRequests = 0;
  InterceptorSkipHeader = 'X-Skip-Interceptor';
  constructor(private loaderService: LoaderService, private router: Router) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.totalRequests++;
    if (!request.headers.has(this.InterceptorSkipHeader))
      this.showLoader();

    if (localStorage.getItem('access-token') != null) {

      const token = localStorage.getItem('access-token');
      const userEmailAddress = localStorage.getItem('userEmailAddress');
      const headers = new HttpHeaders({
        'Authorization': token
      });

      const AuthRequest = request.clone({ headers: headers });

      return next.handle(AuthRequest).pipe(
        tap(res => {
          if (res instanceof HttpResponse) {
            this.decreaseRequests();
          }
        }),
        catchError(err => {

          if (err.statusText == "Unauthorized") {
            this.router.navigate(['/session']);
            throw err;
          }
          this.decreaseRequests();
          throw err;
        })
      );

    } else {

      return next.handle(request).pipe(
        tap(res => {
          if (res instanceof HttpResponse) {
            this.decreaseRequests();
          }
        }),
        catchError(err => {
          if (err.statusText == "Unauthorized") {
            this.router.navigate(['/session']);
            throw err;
          }
          this.decreaseRequests();
          throw err;
        })
      );
    }

  }
  private decreaseRequests() {
    this.totalRequests--;
    if (this.totalRequests === 0) {
      this.loaderService.hide();
    }
  }

  private showLoader(): void {
    this.loaderService.show();
  }

}
