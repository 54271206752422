import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as env from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CurrentuserService {

  constructor(private http: HttpClient) { }
  getPayrollUnitConfiguration() {
    return this.http.get(env.configUrl + "PayrollUnitConfiguration");
  }
  getUserSession() {
    return this.http.get(env.schedulingUrl + "Home/session");
  }
  getPayrollStatus(dateTimePeriodStart: Date, dateTimePeriodEnd: Date) {
    return this.http.get(env.configUrl + "PayrollStatus?dateTimePeriodStart=" + dateTimePeriodStart + "&dateTimePeriodEnd=" + dateTimePeriodEnd);
  }
  getBusinessUnitConfig(id) {
    return this.http.get(env.configUrl + "BusinessUnit/config/"+id);
  }
}
