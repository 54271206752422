export class EmployeeScheduleModel
{
  iEmployeeScheduleId : number;
  iEmployeeId : number;
  iBusinessUnitId:number;
  iEmploymentDetailId:number;
  dateTimeBusinessDate:string;
  dateTimeCheckIn:string;
  dateTimeCheckOut:string;
  tIsTimeOff:boolean = false;
  tIsDayTimeOff:boolean = false;
  strNotes: string;
  iJobTitleId: number;
  strFirstName:string;
  strLastName:string;
  strJobTitleName:string;
  strDepartmentCode: string;
  dblTotalHours: number;
  dateTimeBusinessDateDisplay: string;
}

export class TimeDetails {
  fromTime: any;
  toTime: any;
  strTimeName: string;
  iTimeSlot: number;
}


export class ExportEmployeeSchedule {
  date: Date;
  hours: number;
  day:string;
}

export class ExportEmployeeScheduleTotalHours {
  date: Date;
  totHrs: number;
  day:string;
}